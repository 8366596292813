import React, { useContext } from "react";
import { OidcConfigurationNameContext } from "./contexts/OidcConfigurationNameContext";
import { OidcSecure } from "@axa-fr/react-oidc";
import Chat from "./Chat";

import { ChatOptionsProvider } from "./components/providers/ChatOptionsProvider";
import { SendingProvider } from "./components/providers/SendingProvider";
import { useOidcConfigList } from "./hooks/useOidcConfigList";
import LoginPage from "./LoginPage";
import { PromptTemplatesProvider } from "./components/providers/PromptTemplatesProvider";
import { AuthenticatedLoadingWrapper } from "./components/AuthenticatedLoadingWrapper";
import { PromotionsLoadingWrapper } from "./components/PromotionsLoadingWrapper";
import { AvailablePromotionsCarouselProvider } from "./components/providers/AvailablePromotionsCarouselProvider";
import { RagSourcesProvider } from "./components/providers/RagSourcesProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient();

export const OidcSecureChat = () => {
  const { oidcConfigurationName } = useContext(OidcConfigurationNameContext);
  const { oidcConfigList } = useOidcConfigList();

  let configuration = oidcConfigList.find(
    (c) => c.name === oidcConfigurationName,
  );

  if (configuration == null || oidcConfigurationName === "default") {
    return <LoginPage showSupportLogin={false}/>;
  }

  return (
    <OidcSecure configurationName={oidcConfigurationName}>
      <RagSourcesProvider>
        <ChatOptionsProvider>
          <PromptTemplatesProvider>
            <AvailablePromotionsCarouselProvider>
              <SendingProvider>
                <AuthenticatedLoadingWrapper>
                  <PromotionsLoadingWrapper>
                    <QueryClientProvider client={queryClient}>
                      <ReactQueryDevtools initialIsOpen={true} />
                      <Chat />
                    </QueryClientProvider>
                  </PromotionsLoadingWrapper>
                </AuthenticatedLoadingWrapper>
              </SendingProvider>
            </AvailablePromotionsCarouselProvider>
          </PromptTemplatesProvider>
        </ChatOptionsProvider>
      </RagSourcesProvider>
    </OidcSecure>
  );
};
