import Dexie, { Table } from "dexie";
import IConversationInfo from "../types/interfaces/IConversationInfo";
import IChatMessage from "../types/interfaces/IChatMessage";

Dexie.debug = false;

export class SubClassedDexie extends Dexie {
    conversationInfo!: Table<IConversationInfo>;
    chatMessage!: Table<IChatMessage>;

    constructor() {
        super("myDatabase");
        this.version(2).stores({
            conversationInfo: "++id, name, updated, named",
            chatMessage: "++id, role, content, conversationId, updated, hasFeedback",
        });
    }
}

export const db = new SubClassedDexie();