import { ContentChunk } from "../../../types/classes/ContentChunk";
import { useEffect } from "react";

export const ContentChunkDisplay = ({
  chunk,
  id,
  onUpdate,
}: {
  chunk: ContentChunk;
  id: number;
  value: string;
  onUpdate: (chunkId: number, newValue: string) => void;
}) => {
  useEffect(() => {
    onUpdate(id, chunk.content);
  }, [chunk]);
  return <></>;
};
