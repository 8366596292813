import React from "react";

export default function useErrorQueryParams() {
    const search = window.location.search;

    return React.useMemo(
        () => {
            const params = new URLSearchParams(search)
            return {error: params.get('error'), errorDescription: params.get('error_description')}
        }
        , [search]
    );
}