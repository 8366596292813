import axios from "axios";
import { OidcAccessToken } from "@axa-fr/react-oidc/dist/ReactOidc";
import IChatMessage from "../../types/interfaces/IChatMessage";
import { MessageRole } from "../../types/enums/MessageRole";
import IModel from "../../types/interfaces/IModel";

const API_BASE_URL: string =
  (process.env.REACT_APP_LLMGATEWAY_HOST ?? "") + "/messaging";
axios.defaults.withCredentials = true;

export interface PersistenceThread {
  id?: string;
  name: string;
  created_at?: string;
  metadata?: string;
  authorized_users?: string[];
  model?: string;
  rag?: string[];
  pii?: boolean;
  instructions?: string;
  updated_at?: string;
}

export interface PersistenceMessage {
  id?: string;
  created_at?: string;
  thread_id: string;
  status: string;
  completed_at?: string;
  incomplete_at?: string;
  incomplete_reason?: string;
  role: string;
  text_content?: string;
  image_url?: string;
  refusal?: string;
  metadata?: string;
  authorized_users?: string[];
  model_name?: string;
  citations?: string[];
  has_feedback?: boolean;
}

export const PersistenceAPI = {
  getThreads: async (
    accessToken: OidcAccessToken,
  ): Promise<PersistenceThread[]> => {
    const response = await axios.get(`${API_BASE_URL}/thread`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data;
  },

  createThread: async (
    accessToken: OidcAccessToken,
    thread: PersistenceThread,
  ): Promise<PersistenceThread> => {
    const response = await axios.post(`${API_BASE_URL}/thread`, thread, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data;
  },

  createBlankThread: async (
    accessToken: OidcAccessToken,
  ): Promise<PersistenceThread> => {
    const response = await axios.post(
      `${API_BASE_URL}/thread`,
      {},
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );
    return response.data;
  },

  getThreadById: async (
    accessToken: OidcAccessToken,
    threadId: string,
  ): Promise<PersistenceThread> => {
    const response = await axios.get(`${API_BASE_URL}/thread/${threadId}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data;
  },

  updateThread: async (
    accessToken: OidcAccessToken,
    threadId: string,
    thread: PersistenceThread,
  ): Promise<PersistenceThread> => {
    const response = await axios.put(
      `${API_BASE_URL}/thread/${threadId}`,
      thread,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );
    return response.data;
  },

  deleteThread: async (
    accessToken: OidcAccessToken,
    threadId: string,
  ): Promise<{ message: string }> => {
    const response = await axios.delete(`${API_BASE_URL}/thread/${threadId}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data;
  },

  getMessages: async (
    accessToken: OidcAccessToken,
  ): Promise<PersistenceMessage[]> => {
    const response = await axios.get(`${API_BASE_URL}/message`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data;
  },

  messageAsChatMessage: (
    pMessage: PersistenceMessage,
    modelsList: IModel[],
  ): IChatMessage => {
    const role = (() => {
      switch (pMessage.role) {
        case "user":
          return MessageRole.User;
        case "assistant":
          return MessageRole.Assistant;
        case "system":
          return MessageRole.System;
        default:
          return MessageRole.System;
      }
    })();
    const model = modelsList.find(
      (m) => m.internal_name === pMessage.model_name,
    );
    return {
      role: role,
      content: pMessage.text_content ?? "",
      conversationId: pMessage.thread_id,
      hasFeedback: pMessage.has_feedback ?? false,
      id: pMessage.id ?? "",
      model: model,
      updated: new Date(pMessage.created_at ?? ""),
    };
  },

  chatMessageAsMessage: (chatMessage: IChatMessage): PersistenceMessage => {
    return {
      role: chatMessage.role,
      text_content: chatMessage.content,
      thread_id: chatMessage.conversationId,
      has_feedback: chatMessage.hasFeedback,
      model_name: chatMessage.model?.internal_name ?? "",
      created_at: chatMessage.updated.toISOString(),
      status: "completed",
    };
  },

  getMessagesInThread: async (
    accessToken: OidcAccessToken,
    threadId: string,
  ): Promise<PersistenceMessage[]> => {
    const response = await axios.get(
      `${API_BASE_URL}/thread/${threadId}/message`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );
    return response.data;
  },

  getMessagesInThreadAsChatMessages: async (
    accessToken: OidcAccessToken,
    threadId: string,
    modelsList: IModel[],
  ): Promise<IChatMessage[]> => {
    const response = await axios.get(
      `${API_BASE_URL}/thread/${threadId}/message`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );
    return response.data
      .map((m: PersistenceMessage) =>
        PersistenceAPI.messageAsChatMessage(m, modelsList),
      )
      .sort(
        (a: IChatMessage, b: IChatMessage) =>
          a.updated.getTime() - b.updated.getTime(),
      );
  },

  createMessage: async (
    accessToken: OidcAccessToken,
    message: PersistenceMessage,
  ): Promise<PersistenceMessage> => {
    const response = await axios.post(`${API_BASE_URL}/message`, message, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data;
  },

  createMessageBulk: async (
      accessToken: OidcAccessToken,
      messages: PersistenceMessage[],
  ): Promise<{}> => {
    const response = await axios.post(`${API_BASE_URL}/message/bulk`, messages, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data;
  },

  createMessageFromChatMessage: async (
    accessToken: OidcAccessToken,
    chatMessage: IChatMessage,
  ): Promise<IChatMessage> => {
    const models = chatMessage.model ? [chatMessage.model] : [];
    const response = await axios.post(
      `${API_BASE_URL}/message`,
      PersistenceAPI.chatMessageAsMessage(chatMessage),
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );
    return PersistenceAPI.messageAsChatMessage(response.data, models);
  },

  updateMessage: async (
    accessToken: OidcAccessToken,
    messageId: string,
    message: PersistenceMessage,
  ): Promise<PersistenceMessage> => {
    const response = await axios.put(
      `${API_BASE_URL}/message/${messageId}`,
      message,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );
    return response.data;
  },

  updateMessageFromChatMessage: async (
    accessToken: OidcAccessToken,
    chatMessage: IChatMessage,
  ): Promise<IChatMessage> => {
    const response = await axios.put(
      `${API_BASE_URL}/message/${chatMessage.id}`,
      PersistenceAPI.chatMessageAsMessage(chatMessage),
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );
    return response.data;
  },

  deleteMessage: async (
    accessToken: OidcAccessToken,
    messageId: string,
  ): Promise<{ message: string }> => {
    const response = await axios.delete(
      `${API_BASE_URL}/message/${messageId}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );
    return response.data;
  },
};
