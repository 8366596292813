import { useQuery } from "react-query";
import { PersistenceAPI } from "../functions/requests/persistence";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { useOidcConfigurationName } from "./useOidcConfigurationName";
import IModel from "../types/interfaces/IModel";

export const useConversationListQuery = () => {
  const { oidcConfigurationName } = useOidcConfigurationName();
  const oidcAccessToken = useOidcAccessToken(oidcConfigurationName);
  const queryOptions = {
    staleTime: 0,
    enabled: true,
  };
  return useQuery(
    ["conversationList"],
    () => PersistenceAPI.getThreads(oidcAccessToken.accessToken),
    queryOptions,
  );
};

export const useMessageListQuery = (threadId: string | null | undefined) => {
  const { oidcConfigurationName } = useOidcConfigurationName();
  const oidcAccessToken = useOidcAccessToken(oidcConfigurationName);
  const queryOptions = {
    staleTime: 0,
    enabled: true,
  };
  return useQuery(
    ["messageList", threadId],
    () =>
      threadId != null
        ? PersistenceAPI.getMessagesInThread(
            oidcAccessToken.accessToken,
            threadId,
          )
        : Promise.resolve([]),
    queryOptions,
  );
};

export const useChatMessageListQuery = (
  threadId: string | null | undefined,
  modelList: IModel[],
) => {
  const { oidcConfigurationName } = useOidcConfigurationName();
  const oidcAccessToken = useOidcAccessToken(oidcConfigurationName);
  const queryOptions = {
    staleTime: 0,
    enabled: true,
  };
  return useQuery(
    ["chatMessageList", threadId],
    () =>
      threadId != null
        ? PersistenceAPI.getMessagesInThreadAsChatMessages(
            oidcAccessToken.accessToken,
            threadId,
            modelList,
          )
        : Promise.resolve([]),
    queryOptions,
  );
};
