import { Box } from "@mui/material";
import { useFormFactor } from "../../hooks/useFormFactor";
import { usePromotionsCarousel } from "../../hooks/usePromotionsCarousel";
import { SlideViewer } from "./SlideViewer";

export const PromotionSidebar = () => {
  const formFactor = useFormFactor();
  const { promotionsCarousel } = usePromotionsCarousel();

  if (!promotionsCarousel.carousel.length) {
    return (
      <Box sx={{ width: "280px", marginLeft: "auto", marginRight: "auto" }} />
    );
  }

  return (
    <Box sx={{ width: "280px", marginLeft: "auto", marginRight: "auto" }}>
      <SlideViewer promotionsCarousel={promotionsCarousel} />
    </Box>
  );
};
