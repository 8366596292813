import { ReactNode, useCallback, useEffect, useState } from "react";
import useSessionStorage from "../../hooks/useSessionStorage";
import IOidcConfig from "../../types/interfaces/IOidcConfig";
import { OidcConfigListContext } from "../../contexts/OidcConfigListContext";
import { getOidcConfigurations } from "../../functions/requests/getOidcConfigurations";

export const OidcConfigListProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [oidcConfigList, setOidcConfigList] = useSessionStorage<IOidcConfig[]>(
    "oidcConfigList",
    [],
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const reloadOidcConfigList = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const oidcConfigResponse = await getOidcConfigurations();
      setOidcConfigList((_) =>
        oidcConfigResponse.map((c) => {
          return {
            name: c.name,
            client_id: c.client_id,
            redirect_uri: window.location.origin + c.redirect_uri,
            silent_redirect_uri: window.location.origin + c.silent_redirect_uri,
            scope: c.scope,
            authority: c.authority,
            service_worker_only: false,
            demonstrating_proof_of_possession: false,
          };
        }),
      );
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  }, [setOidcConfigList]);

  useEffect(() => {
    void reloadOidcConfigList();
  }, [reloadOidcConfigList]);

  return (
    <OidcConfigListContext.Provider
      value={{
        oidcConfigList: oidcConfigList,
        loading: loading,
        error: error,
        reload: reloadOidcConfigList,
        name: "OIDC configurations",
      }}
    >
      {children}
    </OidcConfigListContext.Provider>
  );
};
