import { ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { OidcConfigurationNameContext } from "../../contexts/OidcConfigurationNameContext";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import IRAGSource from "../../types/interfaces/IRAGSource";
import { getRAGSources } from "../../functions/requests/getRAGSources";
import { RAGSourcesContext } from "../../contexts/RAGSourcesContext";

export const RagSourcesProvider = ({ children }: { children: ReactNode }) => {
  const { oidcConfigurationName } = useContext(OidcConfigurationNameContext);
  const oidcAccessToken = useOidcAccessToken(oidcConfigurationName);
  const [ragSources, setRagSources] = useState<IRAGSource[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const reloadSources = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const ragSourcesResponse = await getRAGSources(oidcAccessToken);
      setRagSources(ragSourcesResponse);
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  }, [oidcAccessToken]);

  useEffect(() => {
    void reloadSources();
  }, [reloadSources]);

  return (
    <RAGSourcesContext.Provider
      value={{
        ragSources: ragSources,
        loading: loading,
        error: error,
        reload: reloadSources,
        name: "rag sources",
      }}
    >
      {children}
    </RAGSourcesContext.Provider>
  );
};
