import {
  Alert,
  AlertTitle,
  Box,
  Button, Card,
  CssBaseline,
  Typography
} from "@mui/material";
import React, {Fragment, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { useDarkMode } from "./hooks/useDarkMode";
import { useOidcConfigList } from "./hooks/useOidcConfigList";
import { useOidcConfigurationName } from "./hooks/useOidcConfigurationName";
import IOidcConfig from "./types/interfaces/IOidcConfig";
import useErrorQueryParams from "./hooks/useErrorQueryParams";
import useLogoutQueryParams from "./hooks/useLogoutQueryParams";

export type LoginPageProps = {
  showSupportLogin: boolean;
};

export default function LoginPage({ showSupportLogin = false }: LoginPageProps) {
  const { darkModeEnabled } = useDarkMode();
  const { oidcConfigList } = useOidcConfigList();
  const { setOidcConfigurationName } = useOidcConfigurationName();
  const [availableOidcConfigurations, setAvailableOidcConfigurations] = React.useState<IOidcConfig[]>([]);
  const {error, errorDescription} = useErrorQueryParams();
  const { logout } = useLogoutQueryParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (!showSupportLogin) {
      setAvailableOidcConfigurations(oidcConfigList.filter(c => !c.name.toLowerCase().includes("support")));
    } else {
      setAvailableOidcConfigurations(oidcConfigList);
    }


  }, [oidcConfigList, showSupportLogin])
  console.log(oidcConfigList);

  /*
  if (availableOidcConfigurations.length === 1) {
    setOidcConfigurationName(availableOidcConfigurations[0].name);
    navigate("/");
  }

   */
  useEffect(() => {
    if (availableOidcConfigurations.length === 1 && error == null && logout == null) {
      setOidcConfigurationName(availableOidcConfigurations[0].name);
      navigate("/");
    }
  }, [error, availableOidcConfigurations, logout, navigate, setOidcConfigurationName]);

  return (
    <Fragment>
      <CssBaseline />
      {error != null ? (<Alert
        severity={"error"}
    >  <AlertTitle>Authentication Error: {error}</AlertTitle>
        There might be an issue with the OIDC configuration. The authentication provider supplied the following information:<br/> <code>{errorDescription}</code>
      </Alert>) : (<></>)}
      {logout != null ? (<Alert
          severity={"info"}
      >You have been logged out successfully</Alert>) : (<></>)}
      <Card sx={{ml: "auto", mr: "auto", maxWidth: "900px", mt: "100px"}}>
        <Box
          sx={{ minHeight: "50vh" }}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{ gap: "1em" }}
          >
            <img
              alt="logo"
              src={
                process.env.REACT_APP_WHITELABEL_LOGINLOGO ??
                (darkModeEnabled
                  ? process.env.REACT_APP_WHITELABEL_DARKLOGO ??
                    "/logo-dark.png"
                  : process.env.REACT_APP_WHITELABEL_LIGHTLOGO ??
                    "/logo-light.png")
              }
              style={{ maxWidth: "60%" }}
            />
            <Typography variant="h5">Authenticate to Continue</Typography>
            {availableOidcConfigurations.map((c) => (
              <Button
                variant="contained"
                onClick={() => {
                  setOidcConfigurationName(c.name);
                  navigate("/");
                }}
              >
                Continue with {c.name.toString()}
              </Button>
            ))}
          </Box>
        </Box>
      </Card>
    </Fragment>
  );
}
