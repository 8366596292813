import {
  Divider,
  IconButton,
  ListItem,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { ListItemButton } from "@mui/material/";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import React, { Fragment } from "react";
import {
  CalendarMonth,
  Delete,
  Edit,
  MoreVertRounded,
} from "@mui/icons-material";
import { chainEventHandlers } from "material-ui-popup-state/chainEventHandlers";
import formatDateOrDateString from "../functions/formatting/formatDateOrDateString";
import { useSending } from "../hooks/useSending";
import { useFormFactor } from "../hooks/useFormFactor";
import { FormFactor } from "../types/enums/FormFactor";
import { useSidebarOpen } from "../hooks/useSidebarOpen";
import { PersistenceThread } from "../functions/requests/persistence";

export type PersistenceConversationListItemProps = {
  conversation: PersistenceThread;
  onConversationInfoChanged: (thread: PersistenceThread) => void;
  onConversationDeleted: (id: string | undefined) => void;
  onConversationClicked: (id: string | undefined) => void;
  active: boolean;
};

export default function PersistenceConversationListItem({
  conversation,
  onConversationInfoChanged,
  onConversationDeleted,
  onConversationClicked,
  active,
}: PersistenceConversationListItemProps) {
  const { isSending } = useSending();
  const formFactor = useFormFactor();
  const { closeSidebar } = useSidebarOpen();

  function handleItemClick(e: React.MouseEvent) {
    e.stopPropagation();
    e.preventDefault();
  }

  function handleRename(e: React.MouseEvent, p: any) {
    handleItemClick(e);
    let newName = prompt("Enter a new name for the conversation");
    let cleaned = newName ?? "";
    cleaned = cleaned.trim() === "" ? conversation.name : cleaned;
    onConversationInfoChanged({ ...conversation, name: cleaned });
    p.close();
  }

  function handleDelete(e: React.MouseEvent, p: any) {
    handleItemClick(e);
    onConversationDeleted(conversation.id);
    p.close();
  }

  function handleItemMiddleClick(e: React.MouseEvent) {
    if (e.button === 1) {
      e.stopPropagation();
      e.preventDefault();
      let loc = window.location.href;
      loc = loc.replace(window.location.pathname, "/" + conversation.id);
      window.open(loc, "_blank");
    }
  }

  return (
    <Tooltip
      title={`${conversation.name}`}
      placement={formFactor > FormFactor.Mobile ? "right" : "top"}
      arrow
    >
      <ListItemButton
        sx={{ display: "flex", justifyContent: "space-between" }}
        onClick={() => {
          onConversationClicked(conversation.id);
          if (formFactor == FormFactor.Mobile) {
            closeSidebar();
          }
        }}
        onMouseDown={handleItemMiddleClick}
        selected={active}
        disabled={isSending}
      >
        <Typography noWrap>{conversation.name}</Typography>
        <PopupState
          popupId={"rename-" + conversation.name}
          variant={"popover"}
          disableAutoFocus={false}
          parentPopupState={null}
        >
          {(popupState) => (
            <Fragment>
              <IconButton
                {...chainEventHandlers(bindTrigger(popupState), {
                  onClick: (e: React.MouseEvent) => {
                    handleItemClick(e);
                    bindTrigger(popupState).onClick(e);
                  },
                })}
              >
                <MoreVertRounded />
              </IconButton>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={(e) => handleRename(e, popupState)}>
                  <Edit />
                  &nbsp; Rename
                </MenuItem>
                <MenuItem onClick={(e) => handleDelete(e, popupState)}>
                  <Delete />
                  &nbsp; Delete
                </MenuItem>
                <Divider />
                <ListItem>
                  <CalendarMonth />
                  &nbsp;
                  <i>
                    {formatDateOrDateString(
                      conversation.updated_at ?? new Date(),
                      true,
                    )}
                  </i>
                </ListItem>
              </Menu>
            </Fragment>
          )}
        </PopupState>
      </ListItemButton>
    </Tooltip>
  );
}
