import React, { useEffect, useState } from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { useOidcConfigurationName } from '../hooks/useOidcConfigurationName';
import { useMutation, useQueryClient } from 'react-query';
import { db } from "../database/db";
import { PersistenceAPI, PersistenceThread, PersistenceMessage } from '../functions/requests/persistence';
import {
    Typography,
    CircularProgress,
    Dialog,
    DialogContent,
    Button,
    DialogActions,
    DialogTitle
} from '@mui/material';

export const MigrationAssistantModal: React.FC = () => {
    const { oidcConfigurationName } = useOidcConfigurationName();
    const oidcAccessToken = useOidcAccessToken(oidcConfigurationName);
    const [isOpen, setIsOpen] = useState(false);
    const [currentConversationName, setCurrentConversationName] = useState<string | null>(null);
    const [isMigrating, setIsMigrating] = useState(false);
    const [userResponded, setUserResponded] = useState(false);
    const [showMigrationDialog, setShowMigrationDialog] = useState(true);
    const queryClient = useQueryClient();

    const conversations = useLiveQuery(() => db.conversationInfo.toArray());
    const messages = useLiveQuery(() => db.chatMessage.toArray());

    const createThreadMutation = useMutation(
        (thread: PersistenceThread) => PersistenceAPI.createThread(oidcAccessToken.accessToken, thread)
    );

    const deleteAllConversations = async () => {
        if (!conversations) return;

        try {
            setIsMigrating(true);
            for (const conversation of conversations) {
                await db.conversationInfo.delete(conversation.id);
            }
        } finally {
            setIsMigrating(false);
            setIsOpen(false);
            setShowMigrationDialog(false);
        }
    };

    const migrateData = async () => {
        if (!conversations || !messages || !oidcAccessToken || conversations.length === 0 || isMigrating) {
            setIsOpen(false);
            return;
        }
        const conversationsToMigrate = [...conversations];
        const messagesToMigrate = [...messages];
        setIsMigrating(true);
        setIsOpen(true);
        try {
            for (let c = 0; c < conversationsToMigrate.length; c++) {
                const conversation = conversationsToMigrate[c];
                try {
                    setCurrentConversationName(conversation.name);
                    const thread: PersistenceThread = {
                        name: conversation.name,
                        created_at: conversation.updated.toISOString(),
                        updated_at: conversation.updated.toISOString()
                    };

                    const createdThread = await createThreadMutation.mutateAsync(thread);

                    const conversationMessages = messagesToMigrate.filter(
                        msg => msg.conversationId === conversation.id
                    );

                    let toCreate = [];
                    for (let i = 0; i < conversationMessages.length; i++) {
                        const message = conversationMessages[i];
                        const persistenceMessage: PersistenceMessage = {
                            thread_id: createdThread.id!,
                            role: message.role.toLowerCase(),
                            text_content: message.content,
                            status: 'completed',
                            created_at: message.updated.toISOString(),
                            has_feedback: message.hasFeedback,
                            model_name: message.model?.internal_name
                        };
                        toCreate.push(persistenceMessage);
                    }

                    await PersistenceAPI.createMessageBulk(oidcAccessToken.accessToken, toCreate);

                    await db.conversationInfo.delete(conversation.id);
                } catch (error) {
                    console.error(`Error migrating conversation ${conversation.id}:`, error);
                }
            }
        } finally {
            await queryClient.invalidateQueries(['conversationList']);
            setIsMigrating(false);
            setIsOpen(false);
            setShowMigrationDialog(false);
        }
    };

    useEffect(() => {
        if (conversations && messages && oidcAccessToken && !isMigrating && !userResponded && showMigrationDialog) {
            setIsOpen(true);
        }
    }, [conversations, messages, oidcAccessToken]);

    if (!conversations || !messages || conversations.length === 0 || !showMigrationDialog) {
        return null;
    }

    return (
        <Dialog
            open={isOpen}
            disableEscapeKeyDown
            disablePortal={false}
        >
            {isMigrating ? (
                <DialogContent sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        Migrating conversation: {currentConversationName}
                    </Typography>
                    <CircularProgress />
                </DialogContent>
            ) : (
                <>
                    <DialogTitle>Cloud Migration</DialogTitle>
                    <DialogContent>
                        <Typography variant="body1">AI Conversations are moving to the cloud-- everything you worked on before is still saved, but in order to keep using your current chats you'll need to have them uploaded to the cloud.</Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>The upload may take a long time to complete. If you don't have time for this right now and want to get started with cloud conversations, you may choose to postpone the upload to a later time. Or, if you want a clean slate, you may choose to just delete existing offline conversations and start fresh.</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setUserResponded(true);
                                setShowMigrationDialog(false);
                                setIsOpen(false);
                            }}
                        >
                            Later
                        </Button>
                        <Button
                            onClick={deleteAllConversations}
                            color="error"
                        >
                            Delete All
                        </Button>
                        <Button
                            onClick={() => {
                                setUserResponded(true);
                                migrateData();
                            }}
                            variant="contained"
                            color="primary"
                        >
                            Migrate Now
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};