import React, { Fragment, useEffect } from "react";
import { OidcSecureChat } from "./OidcSecureChat";
import { initializePage } from "./functions/initializePage";
import { Loading } from "./Loading";
import { CssBaseline } from "@mui/material";
import { BrowserRouter, Route } from "react-router-dom";
import { ApmRoutes } from "@elastic/apm-rum-react";
import LoginPage from "./LoginPage";
import { useAvailableModels } from "./hooks/useAvailableModels";
import { useOidcConfigList } from "./hooks/useOidcConfigList";

export const AppContainer = () => {
  useEffect(() => {}, []);
  const availableModelsContext = useAvailableModels();
  const oidcConfigListContext = useOidcConfigList();

  useEffect(() => {
    initializePage();
  }, []);

  return (
    <Loading contexts={[availableModelsContext, oidcConfigListContext]}>
      <Fragment>
        <CssBaseline />
        <BrowserRouter>
          <ApmRoutes>
            <Route path="/" element={<OidcSecureChat />} />
            <Route path="/:conversationId" element={<OidcSecureChat />} />
            <Route path="/login" element={<LoginPage showSupportLogin={false}/>} />
            <Route path="/support-login" element={<LoginPage showSupportLogin={true}/>} />
            <Route path="/authentication/callback" element={<LoginPage showSupportLogin={false}/>} />
          </ApmRoutes>
        </BrowserRouter>
      </Fragment>
    </Loading>
  );
};
