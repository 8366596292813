import IChatMessage from "../types/interfaces/IChatMessage";
import {
  Alert,
  Box,
  CircularProgress,
  IconButton,
  Snackbar,
  Toolbar,
} from "@mui/material";
import ComposeArea, { ComposeAreaProps } from "./chat/ComposeArea";
import ChatHistory, { ChatHistoryProps } from "./chat/ChatHistory";
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import sendGatewayRequest from "../functions/requests/sendGatewayRequest";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import readMessageStream from "../functions/requests/readMessageStream";
import { MessageRole } from "../types/enums/MessageRole";
import ConversationSettings from "./ConversationSettings";
import IConversationSettings from "../types/interfaces/IConversationSettings";
import { Close } from "@mui/icons-material";
import sendImageGatewayRequest from "../functions/requests/sendImageGatewayRequest";
import { withTransaction } from "@elastic/apm-rum-react";
import { apm } from "@elastic/apm-rum";
import getDefaultModelFromList from "../functions/comparisons-arrays/getDefaultModelFromList";
import { OidcConfigurationNameContext } from "../contexts/OidcConfigurationNameContext";
import { DarkModeContext } from "../contexts/DarkModeContext";
import { useSending } from "../hooks/useSending";
import { useAvailableModels } from "../hooks/useAvailableModels";
import { useSidebarOpen } from "../hooks/useSidebarOpen";
import { useFormFactor } from "../hooks/useFormFactor";
import { FormFactor } from "../types/enums/FormFactor";
import { useChatOptions } from "../hooks/useChatOptions";
import { nameConversation } from "../functions/requests/nameConversation";
import {
  useChatMessageListQuery,
  useConversationListQuery,
} from "../hooks/useConversationListQuery";
import {
  PersistenceAPI,
  PersistenceThread,
} from "../functions/requests/persistence";
import { useMutation, useQueryClient } from "react-query";
import { OidcAccessToken } from "@axa-fr/react-oidc/dist/ReactOidc";
import { submitFeedback } from "../functions/requests/submitFeedback";

export default withTransaction(
  "ActiveConversationWindow",
  "component",
)(ActiveConversationWindow);

export type ActiveConversationWindowProps = {
  conversationId: string | null | undefined;
  onTranscriptDownloaded: (history: IChatMessage[]) => void;
  defaultSystemPrompt: string;
  useRagByDefault: boolean;
  onLoadingFailed: () => void;
};

function ActiveConversationWindow({
  conversationId,
  onTranscriptDownloaded,
  defaultSystemPrompt,
  useRagByDefault,
    onLoadingFailed,
}: ActiveConversationWindowProps) {
  const { availableModels } = useAvailableModels();
  const persistenceChatMessages = useChatMessageListQuery(
    conversationId,
    availableModels,
  );
  const { isSending, setSending, clearSending } = useSending();
  const { sidebarOpen, sidebarWidth } = useSidebarOpen();
  const conversationList = useConversationListQuery();
  const formFactor = useFormFactor();
  const [stream, setStream] = useState<ChatHistoryProps["stream"]>();
  const [conversationSettingsOpen, setConversationSettingsOpen] =
    useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarText, setSnackbarText] = useState<string>("No notification");
  const { darkModeEnabled } = useContext(DarkModeContext);
  const { oidcConfigurationName } = useContext(OidcConfigurationNameContext);
  const abortControllerRef = useRef<AbortController | null>(null);
  const abort = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  }, []);
  const streamedContentRef = useRef<string>("");
  const accessToken = useOidcAccessToken(oidcConfigurationName);
  const { chatOptions } = useChatOptions();
  const queryClient = useQueryClient();

  const editConversationApi = useMutation({
    mutationFn: async (args: {
      accessToken: OidcAccessToken;
      editedThread: PersistenceThread;
    }) => {
      if (args.editedThread.id === undefined) return;
      return PersistenceAPI.updateThread(
        accessToken.accessToken,
        args.editedThread.id,
        args.editedThread,
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["conversationList"] });
    },
  });

  useEffect(() => {
    if (conversationList.isError) {
      onLoadingFailed();
    }
  }, [conversationList]);

  const addMessageApi = useMutation({
    mutationFn: async (args: {
      chatMessage: IChatMessage;
      accessToken: OidcAccessToken;
    }) => {
      return PersistenceAPI.createMessageFromChatMessage(
        args.accessToken.accessToken,
        args.chatMessage,
      );
    },
    onMutate: async (args: {
      chatMessage: IChatMessage;
      accessToken: OidcAccessToken;
    }) => {
      await queryClient.cancelQueries({
        queryKey: ["chatMessageList", conversationId],
      });
      const previousMessages = queryClient.getQueryData([
        "chatMessageList",
        conversationId,
      ]);
      queryClient.setQueryData(
        ["chatMessageList", conversationId],
        (old: any) => [...old, args.chatMessage],
      );
      return { previousMessages };
    },
    onError: (err, chatMessage, context) => {
      queryClient.setQueryData(
        ["chatMessageList", conversationId],
        context?.previousMessages,
      );
      onLoadingFailed();
    },
    onSettled: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["chatMessageList", conversationId],
      });
    },
  });

  const updateMessageApi = useMutation({
    mutationFn: async (args: {
      chatMessage: IChatMessage;
      accessToken: OidcAccessToken;
    }) => {
      return PersistenceAPI.updateMessageFromChatMessage(
        args.accessToken.accessToken,
        args.chatMessage,
      );
    },
    onMutate: async (args: {
      chatMessage: IChatMessage;
      accessToken: OidcAccessToken;
    }) => {
      await queryClient.cancelQueries({
        queryKey: ["chatMessageList", conversationId],
      });
      const previousMessages: IChatMessage[] | undefined =
        queryClient.getQueryData(["chatMessageList", conversationId]);
      // optimistic update logic?? can be added later
      //queryClient.setQueryData(["chatMessageList", conversationId], (old: any) => [...old, chatMessage])
      return { previousMessages };
    },
    onError: (err, chatMessage, context) => {
      queryClient.setQueryData(
        ["chatMessageList", conversationId],
        context?.previousMessages,
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["chatMessageList", conversationId],
      });
    },
  });

  const deleteMessageApi = useMutation({
    mutationFn: async (args: {
      chatMessage: IChatMessage;
      accessToken: OidcAccessToken;
    }) => {
      return PersistenceAPI.deleteMessage(
        args.accessToken.accessToken,
        args.chatMessage.id,
      );
    },
    onMutate: async (args: {
      chatMessage: IChatMessage;
      accessToken: OidcAccessToken;
    }) => {
      await queryClient.cancelQueries({
        queryKey: ["chatMessageList", conversationId],
      });
      const previousMessages: IChatMessage[] | undefined =
        queryClient.getQueryData(["chatMessageList", conversationId]);
      queryClient.setQueryData(
        ["chatMessageList", conversationId],
        (old: any) =>
          old.filter((m: IChatMessage) => m.id !== args.chatMessage.id),
      );
      return { previousMessages };
    },
    onError: (err, chatMessage, context) => {
      queryClient.setQueryData(
        ["chatMessageList", conversationId],
        context?.previousMessages,
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["chatMessageList", conversationId],
      });
    },
  });

  const currentConversationSettings = (): IConversationSettings => {
    let currentConversation = conversationList?.data?.find(
      (c) => c.id === conversationId,
    );
    return {
      systemPrompt: currentConversation?.instructions ?? "",
      rag: currentConversation?.rag ?? [],
      pii: currentConversation?.pii ?? false,
      model:
        availableModels.find(
          (m) => m.internal_name === currentConversation?.model ?? "",
        ) ?? availableModels[0],
    };
  };

  const handleFeedback = async (
    messageId: string,
    feedback: boolean,
    details: string,
    accessToken: OidcAccessToken,
  ): Promise<void> => {
    await submitFeedback(messageId, feedback, details, accessToken);

    const selectedMessage = persistenceChatMessages.data?.find(
      (m) => m.id === messageId,
    );
    if (selectedMessage === undefined) {
      return Promise.resolve();
    }
    await updateMessageApi.mutateAsync({
      chatMessage: { ...selectedMessage, hasFeedback: true },
      accessToken: accessToken,
    });
  };

  const updatePersistenceSystemPrompt = useCallback(
    (newPrompt: string) => {
      (async function () {
        if (conversationId === null || conversationId === undefined) {
          return;
        }
        try {
          const systemMessage = persistenceChatMessages.data?.find(
            (r) => r.role == MessageRole.System,
          );
          if (systemMessage === undefined) {
            console.error(
              `No such message: System message for ${conversationId}`,
            );
            return;
          }
          systemMessage.content = newPrompt;
          await updateMessageApi.mutateAsync({
            accessToken: accessToken,
            chatMessage: systemMessage,
          });
        } catch (error) {
          console.error(error);
        }
      })();
    },
    [conversationId, persistenceChatMessages.data, accessToken],
  );

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, [conversationId]);

  const updateStreamedMessageText = (chunk: string) => {
    streamedContentRef.current = streamedContentRef.current += chunk;
  };

  const handleRegeneratePersistenceMessage = async () => {
    if (persistenceChatMessages.data === undefined) {
      return;
    }
    const lastMessage =
      persistenceChatMessages.data[persistenceChatMessages.data.length - 1];
    if (lastMessage.id === "!!TEMP") {
      return;
    }
    if (lastMessage.role === MessageRole.Assistant) {
      await deleteMessageApi.mutateAsync({
        accessToken: accessToken,
        chatMessage: lastMessage,
      });
    }
    return handlePersistenceMessageSent(undefined);
  };

  const handlePersistenceMessageSent = useCallback<ComposeAreaProps["onSend"]>(
    async (newMessage: IChatMessage | undefined) => {
      let tr = apm.startTransaction("generate", "llm.message");
      let sp = tr?.startSpan("message");
      const conversationSettings = currentConversationSettings();
      streamedContentRef.current = "";
      if (persistenceChatMessages.data === undefined) {
        throw new Error("chatMessages is undefined");
      }
      const selectedModel =
        typeof conversationSettings.model !== "string"
          ? conversationSettings.model
          : availableModels.find((llm) =>
              // @ts-ignore
              llm.aliases.includes(conversationSettings.model),
            ) ?? getDefaultModelFromList(availableModels);
      const assistantMessagePromise = !selectedModel.image_model
        ? sendGatewayRequest(
            newMessage === undefined
              ? persistenceChatMessages.data
              : [...persistenceChatMessages.data, newMessage],
            selectedModel,
            accessToken,
            conversationSettings.rag,
            conversationSettings.pii,
            chatOptions.show_rag,
            handleSnackbarOpen,
          )
        : sendImageGatewayRequest(
            newMessage === undefined
              ? persistenceChatMessages.data
              : [...persistenceChatMessages.data, newMessage],
            selectedModel,
            accessToken,
            conversationSettings.rag,
            conversationSettings.pii,
            handleSnackbarOpen,
          );
      abortControllerRef.current = assistantMessagePromise.abort;
      const assistantMessage = assistantMessagePromise
        .then((response) => {
          if (response.body === null) {
            throw new Error("Couldn't get message from gateway");
          } else if (!response.ok) {
            return response.json();
          }
          const [stream1, stream2] = response.body.tee();
          setStream(stream1);
          return readMessageStream(stream2, updateStreamedMessageText);
        })
        .then((text): IChatMessage => {
          if (typeof text.chunks !== "string") {
            return {
              id: "!!TEMP",
              conversationId: conversationId ?? "",
              role: MessageRole.Assistant,
              content: "Something went wrong: " + text.detail!,
              updated: new Date(),
              model:
                conversationSettings.model ??
                getDefaultModelFromList(availableModels),
              hasFeedback: false,
            };
          }
          return {
            id: "!!TEMP",
            conversationId: conversationId ?? "",
            role: MessageRole.Assistant,
            content: text.chunks,
            citations: text?.citations,
            updated: new Date(),
            model:
              conversationSettings.model ??
              getDefaultModelFromList(availableModels),
            hasFeedback: false,
          };
        })
        .catch((err) => {
          console.error(err);
          return {
            id: "!!TEMP",
            conversationId: conversationId ?? "",
            role: MessageRole.Assistant,
            content: err.toString(),
            updated: new Date(),
            model:
              conversationSettings.model ??
              getDefaultModelFromList(availableModels),
            hasFeedback: false,
          };
        });
      setSending();
      if (newMessage !== undefined) {
        await addMessageApi.mutateAsync({
          accessToken: accessToken,
          chatMessage: newMessage,
        });
      }
      const assistantMessageReceived = await assistantMessage;
      clearSending();
      await addMessageApi.mutateAsync({
        accessToken: accessToken,
        chatMessage: assistantMessageReceived,
      });
      if (sp) sp.end();
      if (tr) tr.end();
    },
    [
      accessToken,
      persistenceChatMessages.data,
      conversationId,
      setSending,
      clearSending,
      conversationList.data,
      availableModels,
      chatOptions.show_rag,
    ],
  );

  const handleConversationSettingsOpen = () => {
    setConversationSettingsOpen(true);
  };

  const handleConversationSettingsClose = () => {
    setConversationSettingsOpen(false);
  };

  const handleConversationSettingsSave = (
    newSettings: IConversationSettings,
  ) => {
    //setConversationSettings(newSettings);
    let currentConversation = conversationList?.data?.find(
      (c) => c.id === conversationId,
    );
    if (currentConversation === undefined) {
      return;
    }
    editConversationApi
      .mutateAsync({
        accessToken: accessToken,
        editedThread: {
          ...currentConversation,
          name: currentConversation.name ?? "",
          model: newSettings.model.internal_name,
          instructions: newSettings.systemPrompt,
          rag: newSettings.rag,
          pii: newSettings.pii,
        },
      })
      .then(() => updatePersistenceSystemPrompt(newSettings.systemPrompt));
  };

  const handleExportTranscript = () => {
    //onTranscriptDownloaded(chatMessages ?? []);
    onTranscriptDownloaded(persistenceChatMessages.data ?? []);
  };

  useEffect(() => {
    if (persistenceChatMessages.data?.length == 0) {
      addMessageApi.mutate({
        accessToken: accessToken,
        chatMessage: {
          conversationId: conversationId ?? "",
          role: MessageRole.System,
          id: "",
          content: defaultSystemPrompt,
          updated: new Date(),
          model:
            currentConversationSettings().model ??
            getDefaultModelFromList(availableModels),
          hasFeedback: false,
        },
      });
    }
  }, [persistenceChatMessages.data]);

  const cancelStream = useCallback(() => {
    setStream(undefined);
    clearSending();
    if (abort != null) {
      try {
        abort();
      } catch (error) {
        console.error("Aborted.");
      }
    }
  }, [clearSending, abort]);

  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSnackbarOpen = (notificationText: string) => {
    setSnackbarText(notificationText);
    setSnackbarOpen(true);
  };

  const composeBarHeight = 64;

  async function getNamed(id: string): Promise<boolean | undefined> {
    try {
      const conversation: PersistenceThread | undefined =
        conversationList.data?.find((c) => c.id === id);
      return conversation?.name != "Unnamed Conversation";
    } catch (error) {
      console.error("Failed to fetch named value:", error);
      return undefined;
    }
  }

  const namingInProgress = useRef(false);

  useEffect(() => {
    if (
      conversationId &&
      persistenceChatMessages.data &&
      persistenceChatMessages.data.length > 1 &&
      !namingInProgress.current
    ) {
      (async () => {
        namingInProgress.current = true;
        const isNamed = await getNamed(conversationId);
        const conversationSettings = currentConversationSettings();
        if (!isNamed) {
          try {
            const selectedModel =
              typeof conversationSettings.model !== "string"
                ? conversationSettings.model
                : availableModels.find((llm) =>
                    // @ts-ignore
                    llm.aliases.includes(conversationSettings.model),
                  ) ?? getDefaultModelFromList(availableModels);
            const response = await nameConversation(
              persistenceChatMessages.data.slice(1) ?? [],
              selectedModel,
              getDefaultModelFromList(availableModels),
              accessToken,
            );
            if (response.ok) {
              const result = await response.json();
              let updatedName: string =
                result["choices"][0]["message"]["content"];
              if (updatedName.includes(": ")) {
                updatedName = updatedName.split(": ")[1];
              }
              updatedName = updatedName.replaceAll('"', "");
              await editConversationApi.mutateAsync({
                accessToken: accessToken,
                editedThread: {
                  ...conversationList?.data?.find(
                    (c) => c.id === conversationId,
                  ),
                  name: updatedName,
                },
              });
            } else {
              console.error("Failed to automatically name the conversation");
            }
          } catch (error) {
            console.error(
              "Error in automatically naming the conversation:",
              error,
            );
          } finally {
            namingInProgress.current = false;
          }
        } else {
          namingInProgress.current = false;
        }
      })();
    }
  }, [persistenceChatMessages.data]);

  if (conversationId == null) {
    return (
      <>
        <Toolbar />
        <Alert
          severity="info"
          sx={{ ml: "auto", mr: "auto", mt: "1em", width: "fit-content" }}
        >
          Select or add a conversation to begin.
        </Alert>
      </>
    );
  }

  return (
    <>
      <Toolbar />
      <Box component="div">
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarText}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          action={
            <Fragment>
              <IconButton
                aria-label="close"
                color="inherit"
                sx={{ p: 0.5 }}
                onClick={handleSnackbarClose}
              >
                <Close />
              </IconButton>
            </Fragment>
          }
        />
        {persistenceChatMessages.isFetching &&
        (persistenceChatMessages.data ?? []).length == 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              placeItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "50vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <ChatHistory
            //chatMessages={chatMessages ?? []}
            chatMessages={persistenceChatMessages.data ?? []}
            stream={stream}
            //onRegenerateMessage={handleRegenerateMessage}
            onRegenerateMessage={handleRegeneratePersistenceMessage}
            heightOffset={composeBarHeight}
            model={currentConversationSettings().model}
            defaultSystemPrompt={defaultSystemPrompt}
            handleFeedback={handleFeedback}
          />
        )}

        <Box
          position="fixed"
          component="div"
          sx={{
            boxSizing: "border-box",
            minHeight: composeBarHeight,
            maxHeight: `calc(${composeBarHeight}px * 3)`,
            bottom: 0,
            right:
              formFactor > FormFactor.Tablet && chatOptions.use_promotions
                ? "280px"
                : 0,
            left: sidebarOpen ? sidebarWidth : 0,
            margin: 0,
            backgroundColor: darkModeEnabled ? "black" : "white",
            overflow: "none",
          }}
        >
          <ComposeArea
            //onSend={handleMessageSent}
            onSend={handlePersistenceMessageSent}
            enabled={!isSending}
            onConversationSettingsOpen={handleConversationSettingsOpen}
            onExportTranscript={handleExportTranscript}
            onError={handleSnackbarOpen}
            conversationId={conversationId}
            cancelStream={cancelStream}
          />
        </Box>
        <ConversationSettings
          open={conversationSettingsOpen}
          onClose={handleConversationSettingsClose}
          onSave={handleConversationSettingsSave}
          currentRag={currentConversationSettings().rag}
          currentPii={currentConversationSettings().pii}
          currentModel={currentConversationSettings().model}
          currentSystemPrompt={currentConversationSettings().systemPrompt}
          defaultSystemPrompt={defaultSystemPrompt}
        />
      </Box>
    </>
  );
}
