import IChatMessage from "../../types/interfaces/IChatMessage";
import {
  Typography,
  Box,
  Alert,
  List,
  ListItemText,
  ListItemButton,
  Stack,
  Tooltip,
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import CodeBlock from "./CodeBlock";
import ChatImage from "./ChatImage";
import { useUserInfo } from "../../hooks/useUserInfo";
import { useOidcAccessToken, useOidcUser } from "@axa-fr/react-oidc";
import { useOidcConfigurationName } from "../../hooks/useOidcConfigurationName";
import { InfoOutlined } from "@mui/icons-material";
import supersub from "remark-supersub";

export type ChatMessageProps = {
  message: IChatMessage;
  fromUser: boolean;
  streaming: boolean;
};

export default function ChatMessage({ message, fromUser, streaming }: ChatMessageProps) {
  const { oidcConfigurationName } = useOidcConfigurationName();
  const { oidcUser } = useOidcUser(oidcConfigurationName);
  const oidcAccessToken = useOidcAccessToken(oidcConfigurationName);
  const { userName } = useUserInfo(oidcUser, oidcAccessToken);

  let splitMessage = message.content.split("===== ATTACHED FILE BELOW======");
  let cleanedMessage = splitMessage[0];
  let attachedFilesContent = splitMessage.slice(1);

  let attachmentElements = attachedFilesContent.map((fileContent, index) => (
    <details key={index}>
      <Typography variant="overline" component="summary">
        Plain Text from Attached File {index + 1}
      </Typography>
      {fileContent}
    </details>
  ));
  if (message.citations != null && message.citations.length > 0) {
    for (let i = 0; i < message.citations.length; i++) {
      cleanedMessage = cleanedMessage.replaceAll(
        `[${i + 1}]`,
        `[^[${i + 1}]^](${message.citations[i]})`,
      );
    }
  }

  let bodyElement = streaming ? <></> : <Alert severity="info">This message is blank.</Alert>;
  if (cleanedMessage.startsWith("Something went wrong: ")) {
    bodyElement = <Alert severity="error">{cleanedMessage}</Alert>;
  } else if (cleanedMessage.length > 100_000) {
    bodyElement = <p>{cleanedMessage}</p>;
  } else if (cleanedMessage !== "") {
    bodyElement = (
      <ReactMarkdown
        className="markdown"
        remarkPlugins={[remarkGfm, supersub]}
        components={{
          // @ts-ignore
          pre: CodeBlock,
          img: ChatImage,
          a: ({ ...props }) => (
            <a {...props} target="_blank" rel="noopener noreferrer">
              {props.children}
            </a>
          ),
        }}
      >
        {cleanedMessage}
      </ReactMarkdown>
    );
  }

  let footerElement = <></>;
  if (message.citations != null && message.citations.length > 0) {
    footerElement = (
      <Box>
        <hr />
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
          }}
        >
          <Typography variant="button">Web Citations&nbsp;</Typography>
          <Tooltip title="Always double check that the web citations support the AI-generated information.">
            <InfoOutlined fontSize="small" />
          </Tooltip>
        </Stack>
        <List>
          {message.citations.map((c, index) => (
            <ListItemButton
              component="a"
              href={c}
              target="_blank"
              disableGutters
              sx={{ paddingTop: "0", paddingBottom: "0" }}
            >
              <ListItemText key={c} disableTypography={true}>
                <Stack direction="row">
                  <Typography variant="body1">{index + 1}.&nbsp;</Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color:
                        process.env.REACT_APP_WHITELABEL_COLOR ?? "#CC0000",
                      textDecoration: "underline",
                    }}
                  >
                    {c}
                  </Typography>
                </Stack>
              </ListItemText>
            </ListItemButton>
          ))}
        </List>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 0 }}>
      <Typography variant="button" color="primary">
        {fromUser ? userName : message.model?.display_name ?? "Assistant"}
      </Typography>
      <Typography variant="body1" component={"span"}>
        {bodyElement}
        {attachmentElements}
      </Typography>
      {footerElement}
    </Box>
  );
}
