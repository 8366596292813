import { ChangeEvent, useEffect, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";
import { useRagSources } from "../../hooks/useRagSources";

export type RagSourceMultiSelectProps = {
  onRagChange: (newRag: string[]) => void;
  currentRagValue: string[];
};

export default function RagSourceMultiSelect({
  onRagChange,
  currentRagValue,
}: RagSourceMultiSelectProps) {
  const [ragValue, setRagValue] = useState<string[]>(currentRagValue);
  const ragSources = useRagSources();

  const handleRagChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    const contentIndex = ragSources.ragSources.find(
      (rs) => rs.name === event.target.name,
    )?.content_index;
    if (contentIndex === undefined) return;
    setRagValue((prev) =>
      checked ? [...prev, contentIndex] : prev.filter((n) => n != contentIndex),
    );
  };

  useEffect(() => {
    onRagChange(ragValue);
  }, [ragValue]);

  if (!ragSources.ragSources || ragSources.ragSources.length < 1) return <></>;

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        Inform responses with available documents (RAG):
      </FormLabel>
      <FormGroup>
        {ragSources.ragSources.map((rs) => (
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleRagChange}
                name={rs.name}
                key={rs.name}
                checked={ragValue.some((n) => n === rs.content_index)}
              />
            }
            label={rs.name}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}
