import { Box, CssBaseline, Drawer, Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import ConversationList from "./components/ConversationList";
import ActiveConversationWindow from "./components/ActiveConversationWindow";
import IChatMessage from "./types/interfaces/IChatMessage";
import downloadText from "./functions/chat-tools/downloadText";
import getDefaults from "./functions/defaults";
import HelpDialog from "./components/HelpDialog";
import TopBar from "./components/TopBar";
import useLocalStorage from "./hooks/useLocalStorage";
import GlobalSettings from "./components/GlobalSettings";
import { useChatOptions } from "./hooks/useChatOptions";
import { useFormFactor } from "./hooks/useFormFactor";
import { useSidebarOpen } from "./hooks/useSidebarOpen";
import { FormFactor } from "./types/enums/FormFactor";
import { PromotionSidebar } from "./components/promotion/PromotionSidebar";
import { useConversationListQuery } from "./hooks/useConversationListQuery";
import {
  PersistenceAPI,
  PersistenceThread,
} from "./functions/requests/persistence";
import { useMutation, useQueryClient } from "react-query";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { useOidcConfigurationName } from "./hooks/useOidcConfigurationName";
import PersistenceConversationListItem from "./components/PersistenceConversationListItem";
import { useParams } from "react-router-dom";
import getDefaultModelFromList from "./functions/comparisons-arrays/getDefaultModelFromList";
import { useAvailableModels } from "./hooks/useAvailableModels";
import {MigrationAssistantModal} from "./components/MigrationAssistantModal";

export default function Chat() {
  const chatOptionsContext = useChatOptions();
  const chatOptions = chatOptionsContext.chatOptions;
  const formFactor = useFormFactor();
  const { sidebarOpen, sidebarWidth } = useSidebarOpen();
  const [activeConversationId, setActiveConversationId] = useState<
    string | null | undefined
  >(useParams()?.conversationId ?? null);
  const [helpOpen, setHelpOpen] = useState<boolean>(false);
  const [globalSettingsOpen, setGlobalSettingsOpen] = useState<boolean>(false);
  const [defaultSystemPrompt, setDefaultSystemPrompt] = useLocalStorage<string>(
    "defaultSystemPrompt",
    chatOptions.default_system_prompt,
  );
  const { availableModels } = useAvailableModels();
  const { oidcConfigurationName } = useOidcConfigurationName();
  const oidcAccessToken = useOidcAccessToken(oidcConfigurationName);
  const [useRagByDefault, setUseRagByDefault] = useLocalStorage<boolean>(
    "useRagByDefault",
    getDefaults().useRagByDefault,
  );

  const conversationListQuery = useConversationListQuery();
  const queryClient = useQueryClient();

  useEffect(() => {
    const convName =
      conversationListQuery.data?.find((i) => i.id === activeConversationId)
        ?.name ?? "Unnamed Conversation";
    let pageTitle =
      (process.env.REACT_APP_WHITELABEL_NAME ?? "Expedient") + " Chat";
    if (convName !== "Unnamed Conversation") {
      pageTitle =
        convName +
        " - " +
        (process.env.REACT_APP_WHITELABEL_NAME ?? "Expedient") +
        " Chat";
    }
    document.title = pageTitle;
  }, [conversationListQuery.data, activeConversationId]);

  useEffect(() => {
    if (conversationListQuery.data?.find(c => c.id === activeConversationId) == null && (conversationListQuery.data?.length ?? 0) > 0)  {
      setActiveConversationId(null);
    }
      return;
  }, [conversationListQuery.data]);

  useEffect(() => {
    // sets the url to the activeConversationId
    if (activeConversationId === null) return;
    const convName =
      conversationListQuery.data?.find((i) => i.id === activeConversationId)
        ?.name ?? "Unnamed Conversation";
    let pageTitle =
      (process.env.REACT_APP_WHITELABEL_NAME ?? "Expedient") + " Chat";
    if (convName !== "Unnamed Conversation") {
      pageTitle =
        convName +
        " - " +
        (process.env.REACT_APP_WHITELABEL_NAME ?? "Expedient") +
        " Chat";
    }
    document.title = pageTitle;
    if (window.location.pathname !== "/") {
      let loc = window.location.href;
      loc = loc.replace(window.location.pathname, "/" + activeConversationId);
      window.history.pushState({ pageTitle: pageTitle }, "", loc);
    } else {
      window.history.pushState(
        { pageTitle: pageTitle },
        "",
        window.location.href + activeConversationId,
      );
    }
  }, [activeConversationId]);

  const loadingFailedCallback = () => {
    if (activeConversationId !== null) setActiveConversationId(null);
    let pageTitle = (process.env.REACT_APP_WHITELABEL_NAME ?? "Expedient") + " Chat";
    window.history.pushState({ pageTitle: pageTitle }, "", window.location.origin);
  }

  const addConversationApi = useMutation({
    mutationFn: async () => {
      return PersistenceAPI.createThread(oidcAccessToken.accessToken, {
        name: "Unnamed Conversation",
        created_at: new Date().toISOString(),
        instructions: defaultSystemPrompt,
        rag: [],
        pii: false,
        model: getDefaultModelFromList(availableModels).internal_name,
      });
    },
    onSuccess: async (data, variables, context) => {
      setActiveConversationId(data.id);
      await queryClient.refetchQueries(["conversationList"]);
    },
  });

  const deleteConversationApi = useMutation({
    mutationFn: async (id: string | undefined) => {
      if (id === undefined) return;
      if (activeConversationId === id) {
        setActiveConversationId(null);
        window.history.pushState((process.env.REACT_APP_WHITELABEL_NAME ?? "Expedient") + " Chat",
          "",
          window.location.origin,
        );
      }
      return PersistenceAPI.deleteThread(oidcAccessToken.accessToken, id);
    },
    onMutate: async (id: string | undefined) => {
      await queryClient.cancelQueries({ queryKey: ["conversationList"] });
      const previousConversations = queryClient.getQueryData([
        "conversationList",
      ]);
      // optimistic update logic?? can be added later
      queryClient.setQueryData(["conversationList"], (old: any) =>
        old.filter((c: any) => c.id !== id),
      );
      return { previousConversations };
    },
    onSettled: async (data, variables, context) => {
      await queryClient.invalidateQueries(["conversationList"]);
    },
  });

  const editConversationApi = useMutation({
    mutationFn: async (editedThread: PersistenceThread) => {
      if (editedThread.id === undefined) return;
      if (activeConversationId === editedThread.id) {
        document.title =
          editedThread.name +
          " - " +
          (process.env.REACT_APP_WHITELABEL_NAME ?? "Expedient") +
          " Chat";
      }
      return PersistenceAPI.updateThread(
        oidcAccessToken.accessToken,
        editedThread.id,
        editedThread,
      );
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.refetchQueries(["conversationList"]);
    },
  });

  const handleTranscriptDownloaded = (history: IChatMessage[]) => {
    const content = JSON.stringify(history, null, 2);
    const currentConversation = conversationListQuery.data?.find(
      (c) => c.id === activeConversationId,
    );
    downloadText(currentConversation?.name + ".json", content);
  };

  const handleOpenHelp = () => {
    setHelpOpen(true);
  };

  const handleCloseHelp = () => {
    setHelpOpen(false);
  };

  const handleGlobalSettingsOpen = () => {
    setGlobalSettingsOpen(true);
  };

  const handleGlobalSettingsClose = () => {
    setGlobalSettingsOpen(false);
  };

  const handleGlobalSettingsSave = (
    newDefaultSystemPrompt: string,
    newUseRagByDefault: boolean,
  ) => {
    setDefaultSystemPrompt(newDefaultSystemPrompt);
    setUseRagByDefault(newUseRagByDefault);
  };

  return (
    <>
      <HelpDialog open={helpOpen} onClose={handleCloseHelp} />
      <GlobalSettings
        open={globalSettingsOpen}
        currentDefaultSystemPrompt={defaultSystemPrompt}
        currentUseRagByDefault={useRagByDefault}
        onSave={handleGlobalSettingsSave}
        onClose={handleGlobalSettingsClose}
      />
      <Box>
        <Box>
          <CssBaseline />
          <MigrationAssistantModal/>
          <TopBar
            onHelpOpened={handleOpenHelp}
            onGlobalSettingsOpened={handleGlobalSettingsOpen}
          />
          <Drawer
            sx={{
              width: formFactor === FormFactor.Mobile ? "100vw" : sidebarWidth,
              "& .MuiDrawer-paper": {
                width:
                  formFactor === FormFactor.Mobile ? "100vw" : sidebarWidth,
                boxSizing: "border-box",
                overflowX: "hidden",
              },
            }}
            open={sidebarOpen}
            variant="persistent"
            anchor="left"
          >
            <Toolbar />
            <ConversationList
              onConversationAdded={addConversationApi.mutate}
              onGlobalSettingsOpened={handleGlobalSettingsOpen}
              conversationQueryStatus={conversationListQuery.isFetching}
            >
              {conversationListQuery.data
                ?.sort(
                  (a, b) =>
                    new Date(b.updated_at ?? "").getTime() -
                    new Date(a.updated_at ?? "").getTime(),
                )
                .map((c) => (
                  <PersistenceConversationListItem
                    conversation={c}
                    onConversationInfoChanged={editConversationApi.mutate}
                    onConversationDeleted={deleteConversationApi.mutate}
                    onConversationClicked={() => setActiveConversationId(c.id)}
                    active={c.id === activeConversationId}
                    key={c.id}
                  />
                ))}
            </ConversationList>
          </Drawer>
          <Box
            component="main"
            sx={{
              ml:
                formFactor === FormFactor.Mobile
                  ? 0
                  : sidebarOpen
                    ? `${sidebarWidth}px`
                    : 0,
              mt: 0,
              mb: 0,
              mr:
                formFactor > FormFactor.Tablet && chatOptions.use_promotions
                  ? `${sidebarWidth}px`
                  : 0,
              width:
                formFactor === FormFactor.Mobile
                  ? "100%"
                  : sidebarOpen
                    ? `calc(100% - ${formFactor > FormFactor.Tablet && chatOptions.use_promotions ? sidebarWidth * 2 : sidebarWidth}px)`
                    : `calc(100% - ${formFactor > FormFactor.Tablet && chatOptions.use_promotions ? sidebarWidth : 0}px)`,
            }}
          >
            {" "}
            {activeConversationId != null ? (
              <ActiveConversationWindow
                conversationId={activeConversationId}
                onTranscriptDownloaded={handleTranscriptDownloaded}
                defaultSystemPrompt={defaultSystemPrompt}
                useRagByDefault={useRagByDefault}
                onLoadingFailed={loadingFailedCallback}
              />
            ) : (
              <></>
            )}
          </Box>
          {formFactor > FormFactor.Tablet && chatOptions.use_promotions ? (
            <Drawer variant="permanent" anchor="right">
              <Toolbar />
              <PromotionSidebar />
            </Drawer>
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </>
  );
}
