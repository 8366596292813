import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import pdfToText from "react-pdftotext";
import readXlsxFile from "read-excel-file";
import JSZip from "jszip";
import IModel from "../../types/interfaces/IModel";

/**
 * Identify and parse the content of an uploaded file based on its file extension.
 *
 * @param {File} file - The uploaded file to be identified and parsed.
 * @param {IModel} currentModel - The model being used in the current conversation
 * @return {Promise<any>} A Promise that resolves to the parsed content of the file,
 *                        or undefined if an error occurs during parsing.
 */
export default function identifyAndParseUploadedFile(
  file: File,
  currentModel?: IModel,
): Promise<any> | undefined {
  let fileName = file.name;
  if (fileName.endsWith("docx")) {
    return file
      .arrayBuffer()
      .then((data) => {
        const zip = new PizZip(data);
        const doc = new Docxtemplater(zip, { linebreaks: true });
        return Promise.resolve(doc.getFullText());
      })
      .catch((error) => {
        console.error("There was an error parsing the uploaded file", error);
        return undefined;
      });
  } else if (fileName.endsWith("pptx")) {
    return file
      .arrayBuffer()
      .then((data: ArrayBuffer) => {
        return JSZip.loadAsync(data)
          .then((zip: JSZip) => {
            const slidePromises: Promise<string>[] = [];
            zip.forEach((relativePath: string, zipEntry: JSZip.JSZipObject) => {
              if (
                relativePath.includes("ppt/slides/slide") &&
                relativePath.endsWith(".xml")
              ) {
                // Extract and process text
                slidePromises.push(
                  zipEntry.async("text").then((textContent: string) => {
                    // Parse XML to extract text elements
                    const parser = new DOMParser();
                    const xmlDoc = parser.parseFromString(
                      textContent,
                      "application/xml",
                    );
                    const textNodes = xmlDoc.getElementsByTagName("a:t");
                    const textArray = Array.from(textNodes).map(
                      (node) => node.textContent || "",
                    );
                    return textArray.join(" ");
                  }),
                );
              }
            });
            return Promise.all(slidePromises);
          })
          .then((slidesText: string[]) => {
            const combinedText = slidesText.join(" "); // Combine text from all slides
            return Promise.resolve(combinedText);
          });
      })
      .catch((error: Error) => {
        console.error(
          "There was an error parsing the uploaded .pptx file",
          error,
        );
        return undefined;
      });
  } else if (fileName.endsWith("pdf")) {
    try {
      const txt = pdfToText(file);
      return Promise.resolve(txt);
    } catch (error) {
      console.error("There was an error parsing the uploaded file", error);
      return Promise.resolve(undefined);
    }
  } else if (fileName.endsWith("xlsx")) {
    return readXlsxFile(file)
      .then((rows) => {
        return Promise.resolve(JSON.stringify({ rows: rows }));
      })
      .catch((error) => {
        console.error("There was an error parsing the uploaded file", error);
        return undefined;
      });
  } else if (
    fileName.endsWith("txt") ||
    fileName.endsWith("csv") ||
    fileName.endsWith("json") ||
    fileName.endsWith("py") ||
    fileName.endsWith("java") ||
    fileName.endsWith("c")
  ) {
    return file.text();
  }
}
