import IPromotionsCarousel from "../../types/interfaces/IPromotionsCarousel";

export interface IPromotionOptions {
  count?: number;
  width?: number;
  height?: number;
}

/**
 * Retrieves a promotions carousel from the server
 *
 * @param {Object} options An object containing optional parameters for the promotions carousel request.
 * @param {number} [options.count=10] The number of promotions to retrieve.
 * @param {number} [options.width=300] The width of the carousel.
 * @param {number} [options.height=250] The height of the carousel.
 *
 * @return {Promise<IPromotionsCarousel>} A promise that resolves with the promotions carousel data.
 */
export async function getPromotionsCarousel({
  count = 10,
  width = 300,
  height = 250,
}: IPromotionOptions = {}): Promise<IPromotionsCarousel> {
  const host: string =
    process.env.REACT_APP_PROMOTIONS_HOST ??
    "https://partners-ads.expedient.cloud";
  const headers: HeadersInit = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  let resp = await fetch(`${host}/carousel?count=${count}`, {
    method: "GET",
    headers: headers,
    signal: AbortSignal.timeout(5000),
  });
  return await resp.json();
}
