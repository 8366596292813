import React from "react";

export default function useLogoutQueryParams() {
    const search = window.location.search;

    return React.useMemo(
        () => {
            const params = new URLSearchParams(search)
            return {logout: params.get('logout')}
        }
        , [search]
    );
}