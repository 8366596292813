import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { EditNote, Settings } from "@mui/icons-material";
import { useOidcAccessToken, useOidcUser } from "@axa-fr/react-oidc";
import { withTransaction } from "@elastic/apm-rum-react";
import DarkModeToggle from "./DarkModeToggle";
import { useFormFactor } from "../hooks/useFormFactor";
import { useOidcConfigurationName } from "../hooks/useOidcConfigurationName";
import React, { ReactNode } from "react";
import { useUserInfo } from "../hooks/useUserInfo";
import { FormFactor } from "../types/enums/FormFactor";
import { PromotionSidebar } from "./promotion/PromotionSidebar";
import { useChatOptions } from "../hooks/useChatOptions";
import LogoutButton from "../components/Controls/LogoutButton";

export type ConversationListProps = {
  onConversationAdded: () => void;
  onGlobalSettingsOpened: () => void;
  conversationQueryStatus: boolean;
  children: ReactNode;
};

export default withTransaction(
  "ConversationList",
  "component",
)(ConversationList);
function ConversationList({
  onConversationAdded,
  onGlobalSettingsOpened,
  children,
}: ConversationListProps) {
  const { oidcConfigurationName } =
    useOidcConfigurationName();
  const { oidcUser } = useOidcUser(oidcConfigurationName);
  const oidcAccessToken = useOidcAccessToken(oidcConfigurationName);
  const { userName, roles } = useUserInfo(oidcUser, oidcAccessToken);
  const formFactor = useFormFactor();
  const { chatOptions } = useChatOptions();
  return (
    <>
      <Box sx={{ overflow: "auto" }}>
        <List>
          {formFactor === FormFactor.Mobile ? (
            <>
              <ListItem>
                <Box
                  display="flex"
                  sx={{ padding: "0.25em" }}
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Box>
                    <Tooltip title={"Global Settings"}>
                      <IconButton onClick={onGlobalSettingsOpened}>
                        <Settings />
                      </IconButton>
                    </Tooltip>
                    <DarkModeToggle />
                  </Box>
                  <Tooltip
                    title={"Available Roles: " + (roles ?? [""].join(", "))}
                  >
                    <Typography>
                      <b>{userName}</b>
                    </Typography>
                  </Tooltip>
                  <Box component="div">
                    <LogoutButton/>
                  </Box>
                </Box>
              </ListItem>
              <Divider />
            </>
          ) : (
            <></>
          )}
          {formFactor <= FormFactor.Tablet && chatOptions.use_promotions ? (
            <>
              <PromotionSidebar />
              <Divider />
            </>
          ) : (
            <></>
          )}
          <ListItemButton
            onClick={onConversationAdded}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <EditNote />
            &nbsp;
            <Typography>New Conversation</Typography>
          </ListItemButton>
          <Divider />
          {children}
        </List>
      </Box>
    </>
  );
}
