import {IconButton, Tooltip} from "@mui/material";
import {Logout} from "@mui/icons-material";
import React from "react";
import {useOidcConfigurationName} from "../../hooks/useOidcConfigurationName";
import {useOidc, useOidcAccessToken, useOidcUser} from "@axa-fr/react-oidc";

import {useUserInfo} from "../../hooks/useUserInfo";
import {useNavigate} from "react-router-dom";
import {useOidcConfigList} from "../../hooks/useOidcConfigList";

export default function LogoutButton() {
    const { oidcConfigurationName, clearOidcConfigurationName } =
        useOidcConfigurationName();
    const oidc = useOidc(oidcConfigurationName);
    const { oidcUser } = useOidcUser(oidcConfigurationName);
    const oidcAccessToken = useOidcAccessToken(oidcConfigurationName);
    const { userName } = useUserInfo(oidcUser, oidcAccessToken);
    const navigate = useNavigate();
    const {oidcConfigList} = useOidcConfigList();

    const handleLogout = () => {
        let configuration = oidcConfigList.find(
            (c) => c.name === oidcConfigurationName,
        );
        if ((configuration?.name ?? "").toLowerCase().includes("azure")) {
            const origin = encodeURIComponent(window.location.origin) + "/login?logout=true";
            clearOidcConfigurationName();
            sessionStorage.clear();
            window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${origin}`;
        } else {
            oidc.logout(null, { "no_reload:oidc": "true" }).then(() => {
                clearOidcConfigurationName();
                sessionStorage.clear();
                navigate("/login?logout=true");
            });
        }};
    return (<Tooltip title={"Log out of " + userName + "'s account"}>
        <IconButton onClick={handleLogout}>
            <Logout />
        </IconButton>
    </Tooltip>)
}