import {
  ArrowBackIos,
  ArrowForwardIos,
} from "@mui/icons-material";
import IPromotion from "../../types/interfaces/IPromotion";

export type PromotionSlideProps = {
  promotion: IPromotion;
  nextSlideCallback: () => void;
  prevSlideCallback: () => void;
};

export const PromotionSlide = ({
  promotion,
  nextSlideCallback,
  prevSlideCallback,
}: PromotionSlideProps) => {
  return (
    <div
      style={{ maxWidth: "100%", maxHeight: "100%" }}
      className="promotion-slide"
    >
      <button className="prev-button slide-button" onClick={prevSlideCallback}>
        <ArrowBackIos />
      </button>
      <a href={promotion.link_url} target="_blank">
        <img
          style={{ maxWidth: "100%", maxHeight: "100%" }}
          src={promotion.image_url}
          alt={promotion.alt_text}
          width={promotion.width}
          height={promotion.height}
        />
      </a>
      <button className="next-button slide-button" onClick={nextSlideCallback}>
        <ArrowForwardIos />
      </button>
    </div>
  );
};
